import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { catchError, retry, throwError } from 'rxjs'
import { environment } from '../../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private http = inject(HttpClient)

  private _roles: string[] = []

  constructor() {}

  // Getter for user roles to maintain encapsulation
  get roles() {
    console.log('USER ROLES: ', this._roles)
    return this._roles
  }

  // Setter for user roles to maintain encapsulation
  set roles(roles: string[]) {
    this._roles = roles
    // Optionally, perform additional actions when roles are set, like logging
    // console.log('USER ROLES: ', roles)
  }

  getUserData(access_token: string) {
    return this.http
      .get(`${environment.wp_url}/oauth/me`, {
        params: new HttpParams()
          .set('access_token', access_token)
          .set('cmp_bypass', '6fcd0d38e093472cad6c9bf0191c642b'),
      })
      .pipe(
        retry(3),
        catchError((error) => {
          console.error('Failed to fetch user data', error)
          return throwError(() => new Error('Failed to fetch user data'))
        })
      )
  }

  setUserData({
    display_name,
    user_email,
    user_nicename,
    roles,
  }: {
    display_name: string
    user_email: string
    user_nicename: string
    roles: string[]
  }) {
    localStorage.setItem('display_name', display_name)
    localStorage.setItem('user_email', user_email)
    localStorage.setItem('user_nicename', user_nicename)
    this._roles = roles
  }

  resetUserData() {
    localStorage.removeItem('display_name')
    localStorage.removeItem('user_email')
    localStorage.removeItem('user_nicename')
    this._roles = []
  }
}
